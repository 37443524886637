import { NavLink } from 'react-router-dom';
import css from './index.module.scss';

const Header = () => (
  <header>
    <ul className={css.nav}>
      <li>
        <NavLink to="/solution" activeClassName={css.selected}>
          解决方案
        </NavLink>
      </li>
      <li>
        <NavLink to="/products" activeClassName={css.selected}>
          产品中心
        </NavLink>
      </li>
      <li>
        <NavLink to="/" exact activeClassName={css.active}>
          <img style={{ verticalAlign: 'middle', marginBottom: 15 }} src="/images/logo.svg" alt="云腾未来" />
        </NavLink>
      </li>
      <li>
        <NavLink to="/employment" activeClassName={css.selected}>
          人才招聘
        </NavLink>
      </li>
      <li>
        <NavLink to="/about" activeClassName={css.selected}>
          关于我们
        </NavLink>
      </li>
    </ul>
  </header>
);

export default Header;
