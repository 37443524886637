import { useState } from 'react';
// import { Icon } from 'common/svg';
// import { Rhombus } from 'common/svg/icon';
import css from './index.module.scss';
function Solution() {
  const [current, setCurrent] = useState(0);

  return (
    <>
      <div className={css.line}></div>
      <div className={css.tab}>
      <img src="./images/soloution_bg.svg" alt="" />
      <ul className={css.nav}>
        <li
          onMouseOver={() => {
            setCurrent(0);
          }}
          className={current === 0 ? css.selectd : ''}
        >
          区域医疗
        </li>
        <li
          onMouseOver={() => {
            setCurrent(1);
          }}
          className={current === 1 ? css.selectd : ''}
        >
          智慧金融
        </li>
        <li
          onMouseOver={() => {
            setCurrent(2);
          }}
          className={current === 2 ? css.selectd : ''}
        >
          数字政务
        </li>
        <li
          onMouseOver={() => {
            setCurrent(3);
          }}
          className={current === 3 ? css.selectd : ''}
        >
          智慧文旅
        </li>
        <li
          onMouseOver={() => {
            setCurrent(4);
          }}
          className={current === 4 ? css.selectd : ''}
        >
          智慧零售
        </li>
      </ul>      
      <div className={css.content}>
        <div className={css.item}>
          <img className={css.image02} src="/images/index_bg04.png" alt="" />
          <img className={css.image01} src="/images/index_pic01.png" alt="" />
          {current === 0 && (
            <>
              <div className={css.right}>
                <strong>—&emsp;方案优势&emsp;—</strong>
                <ul className={css.containFourUl}>
                  <li>
                    <img alt="" src="images/p01.svg" />
                    <span>强大的技术优势</span>
                    <p>
                      依托腾讯的基础实施能力、人才优势与图像识别、大数据、人工智能等技术积累，助力医疗大健康产业智慧化升级。
                    </p>
                  </li>
                  <li>
                  <img alt="" src="images/p05.svg" />
                    <span>丰富的产品及解决方案</span>
                    <p>
                      整合腾讯云、微信、觅影、视频和支付等产品及合作伙伴的产品服务，提供全行业、全流程的医疗大健康解决方案。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/p02.svg" />
                    <span>强大的连接能力</span>
                    <p>
                      基于腾讯互联网技术与服务，建立机构与机构之间、机构与用户之间的强连接，助力全方位、全生命周期的大健康产业发展。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/p06.svg" />
                    <span>构建全链条产业生态</span>
                    <p>
                      打造开放平台，联合合作伙伴，构建覆盖医疗、康养、医药、器械、流通、保险、服务等全链条的医疗大健康生态。
                    </p>
                  </li>
                </ul>
                <strong>—&emsp;解决方案&emsp;—</strong>
                <ul className={css.containUl}>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/solution05.svg" />
                    <span>智慧就医平台</span>
                    <p>
                      患者通过微信进行咨询、挂号、诊间支付、检查预约、查报告、查病历等操作，缓解百姓“三长一短”的看病难题，减轻医院运营压力。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/solution02.svg" />
                    <span>远程协同平台</span>
                    <p>
                      缓解医疗资源分布不均问题，通过远程会诊、远程门诊、远程示教、双向转诊，助力优质医疗资源的共享和快速下沉。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/solution03.svg" />
                    <span>手术直播教学系统</span>
                    <p>
                      通过直播清晰逼真的手术操作过程，让学生犹如现场学习，教学医师注意力不再受到影响、手术室受到交叉感染率大大降低。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/solution04.svg" />
                    <span>影像数据管理平台</span>
                    <p>
                      无需扩建医院内部的数据中心，数据互通共享、便捷使用，为长期的健康信息保存提供安全、灵活、可扩展和可持续的支撑。
                    </p>
                  </li>
                </ul>
                <strong>—&emsp;部署模式&emsp;—</strong>
                <ul className={css.containOneUl}>
                  <li style={{ minHeight: 140 }}>
                    <span><b />公有云</span>
                    <p>
                      轻资产化运作，全部信息化基础设施使用腾讯公有云资源，包括云主机、云存储、云数据库等，真正做到省心、省力、省钱，安全稳定放心。<br />
                      适用客户一：医联体、医疗集团、影像中心<br />
                      适用客户二：民营医疗机构、基因测序、体检机构、健康管理机构、养老机构、药企、器械企业、药械流通
                    </p>
                  </li>
                  <li style={{ minHeight: 140 }}>
                    <span><b />专有云</span>
                    <p>
                      为满足医疗大健康客户的资源定制化需求，我们提供专有物理隔离、独立网络核心的合规 IDC 及云平台，支持统一管控，共享腾讯网络基础设施及安全防护能力。<br />
                      适用客户一：公立医院、卫健委、医联体、医疗集团、影像中心<br />
                      适用客户二：民营医疗机构、基因测序、体检机构、健康管理机构、养老机构、药企、器械企业、药械流通
                    </p>
                  </li>
                  <li style={{ minHeight: 140 }}>
                    <span><b />混合云</span>
                    <p>
                      根据客户的业务需求，可实现部分数据、业务部署在公有云，部分数据和业务部署在专有云的混合云部署模式，并可通过统一的运管平台实行统一管控。<br />
                      适用客户一：公立医院、卫健委、医联体、医疗集团、影像中心<br />
                      适用客户二：民营医疗机构、基因测序、体检机构、健康管理机构、养老机构、药企、器械企业、药械流通
                    </p>
                  </li>
                </ul>
              </div>
            </>
          )}
          {current === 1 && (
            <>
              <div className={css.right}>
                <strong>—&emsp;方案优势&emsp;—</strong>
                <ul className={css.containFourUl}>
                  <li>
                    <img alt="" src="images/finaincal01.svg" />
                    <span>多中心金融合规专区</span>
                    <p>
                      “两地六中心”合规机房，通过等保四级、可信云等认证，助力企业顺利通过合规验收，数据异地自动同步。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/finaincal02.svg" />
                    <span>30+安全机制立体防护</span>
                    <p>
                      多重防护保障数据安全，秒级抵御网络攻击，P级黑产数据，90%恶意用户识别率，降低金融欺诈风险。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/finaincal03.svg" />
                    <span>社交大数据连接亿万用户</span>
                    <p>
                      多渠道场景化曝光引流，连接亿万用户，个性化用户画像及社交大数据，精准营销，挖掘新业务。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/finaincal04.svg" />
                    <span>兼容传统金融业务架构</span>
                    <p>
                      支持Oracle、金融防火墙架构，混合云部署，按需交付，轻松拓展业务，上云金融软件数十款，成功经验即刻复制。
                    </p>
                  </li>
                </ul>
                <strong>—&emsp;解决方案&emsp;—</strong>
                <ul className={css.containUl}>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small01.svg" />
                    <span>保险</span>
                    <p>
                      根据客户对物理隔离、独立机房、灾备方案、监管要求等需求，提供符合要求的金融云服务，包括：核心系统、风控管理、理赔业务、产品销售等。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small02.svg" />
                    <span>银行</span>
                    <p>
                      腾讯金融云为银行行业量身定制数字化转型解决方案，具备从基础设施搭建到业务应用的整体服务能力，包括：系统建设、业务支持、能力输出、数字化银行等。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small03.svg" />
                    <span>证券</span>
                    <p>
                      基于证券行业用户分布广、网络要求高、业务波动大等问题提出专属解决方案，包括：交易系统、行情系统、大数据、业务创新等。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small04.svg" />
                    <span>泛金融</span>
                    <p>
                      为消费金融、产业金融、金控、支付、金融科技等领域客户提供数字化转型、业务创新等解决方案，包括：大数据、系统建设、安全建设、业务创新等。
                    </p>
                  </li>
                </ul>
                <strong>—&emsp;部署模式&emsp;—</strong>
                <ul className={css.containOneUl}>
                  <li style={{ minHeight: 80 }}>
                    <span><b />公有云</span>
                    <p>
                      腾讯云认证的金融客户，在公有云中购买云服务资源，可享受金融客户定制的安全登录功能、更高安全防护及专属开发测试运营一体化环境。<br />
                      针对的行业客户：P2P、小贷、众筹、网销渠道等互联网金融客户。
                    </p>
                  </li>
                  <li style={{ minHeight: 80 }}>
                    <span><b />金融专区</span>
                    <p>
                      提供符合一行三会金融监管要求的金融专区。独立的金融行业专用机房，提供金融数据库TDSQL、物理服务器托管等专属产品服务。<br />
                      适合的机构：保险，银行，证券，基金，消费金融，交易所，征信公司。
                    </p>
                  </li>
                  <li style={{ minHeight: 80 }}>
                    <span><b />专有云</span>
                    <p>
                      为金融机构单客户提供专有云。专有物理隔离、独立网络核心的合规IDC，支持统一管控，共享腾讯网络基础设施及安全防护能力。<br />
                      适合的机构：银行，保险，证券，基金等大中型金融机构。
                    </p>
                  </li>
                </ul>                
              </div>
            </>
          )}
          {current === 2 && (
            <>
              <div className={css.right}>
                <strong>—&emsp;方案优势&emsp;—</strong>
                <ul className={css.containFourUl}>
                  <li>
                    <img alt="" src="images/gov01.svg" />
                    <span>丰富的产品体系</span>
                    <p>
                      提供计算、存储、网络、安全、大数据、AI、微信、QQ、视频、支付等产品，助力政务机构业务创新。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/gov02.svg" />
                    <span>强大的连接能力</span>
                    <p>
                      通过大数据与平台资源整合的能力，助力数据共享平台的建设，连接亿万用户，为政务机构提供更贴近民生的服务。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/gov03.svg" />
                    <span>海量的运营经验</span>
                    <p>
                      丰富的互联网运营经验，多种安全防护机制，为各政务提供民生服务解决方案的同时，保证运营的稳定与安全。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/gov04.svg" />
                    <span>混合云管理</span>
                    <p>
                      提供全方位混合云管理方案，包含内网级混合云、AI、大数据等云服务，满足多层次、全方位的需求。
                    </p>
                  </li>
                </ul>
                <strong>—&emsp;解决方案&emsp;—</strong>
                <ul className={css.containUl}>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small05.svg" />
                    <span>数字政务</span>
                    <p>
                      打造“数字政务”工具箱，从基础资源建设到应用开发，全维度做强政务开放平台，搭建“数字政务”生态圈，辅助政府的数字化转型升级。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small06.svg" />
                    <span>政务协同平台</span>
                    <p>
                      基于电子政务云 SaaS 化部署建设的协同办公系统，支持多终端多系统运行，利用政务微信的连接能力，实现政府部门间的协同运作与移动办公。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small07.svg" />
                    <span>一网统管</span>
                    <p>
                      通过打造“一图多景、城市体征、有呼必应、综合指挥”四大能力，构建以块为统筹、向基层赋能的跨部门、跨层级的智慧城市运行管理平台。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small08.svg" />
                    <span>数字乡村</span>
                    <p>
                      为政府建立农村产业融合发展体系，建设生态宜居乡村，繁荣乡村文化，健全乡村治理体系，改善农村民生，助力农民精准脱贫、引领乡村振兴。
                    </p>
                  </li>
                </ul>
                <strong>—&emsp;部署模式&emsp;—</strong>
                <ul className={css.containOneUl}>
                  <li style={{ minHeight: 80 }}>
                    <span><b />公有云</span>
                    <p>
                      基于提供面向公众的政务服务公有云资源，将公有云能力与微信、自媒体等深度整合，提供面向政务服务的一站式整合的解决方案。<br />
                      针对的行业领域：门户网站、网厅应用、移动服务等。
                    </p>
                  </li>
                  <li style={{ minHeight: 80 }}>
                    <span><b />私有云</span>
                    <p>
                      为政务客户提供本地化专属云服务，私密性强，安全合规，同时可根据客户需求进行深度定制，可利用既有闲置硬件。<br />
                      针对的行业领域：政务云平台，政府办公专用平台等。
                    </p>
                  </li>
                  <li  style={{ minHeight: 80 }}>
                    <span><b />政务云专区</span>
                    <p>
                    专为政务行业量身定制，遵从政务级安全合规要求，完全独立于腾讯公有云，同时兼具公有云弹性特性，可承载非涉密的重要政务业务。<br />
                    针对的行业领域：政务 SaaS 产品、政务互联网业务、地方政务云服务等。
                    </p>
                  </li>
                </ul>                
              </div>
            </>
          )}
          {current === 3 && (
            <>
              <div className={css.right}>
                <strong>—&emsp;方案优势&emsp;—</strong>
                <ul className={css.containFourUl}>
                  <li>
                    <img alt="" src="images/jour01.svg" />
                    <span>顶层规划设计</span>
                    <p>
                      系统地为目的地、政府、景区、游客等提供基于全流程服务和管理的智慧文旅应用体系。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/jour02.svg" />
                    <span>智慧产品矩阵</span>
                    <p>
                      整合腾讯产品矩阵和先进技术，持续研发创新产品，实现各文旅场景智慧化及产业协同式发展。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/jour03.svg" />
                    <span>文化孵化平台</span>
                    <p>
                      用科技+文化助力中华文明复兴，活化传统文化IP蕴藏的巨大价值，使文化资源向文化资产转化，并提供文创解决方案。
                    </p>
                  </li>
                  <li>
                    <img alt="" src="images/jour04.svg" />
                    <span>产业资源及生态</span>
                    <p>
                      整合协同产业优质合作伙伴，优势互补，构建发展的命运共同体，为文旅产业提供系统化深度服务能力。
                    </p>
                  </li>
                </ul>
                <strong>—&emsp;解决方案&emsp;—</strong>
                <ul className={css.containUl}>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small09.svg" />
                    <span>智慧全域旅游</span>
                    <p>
                      通过大数据、人工智能等技术，系统地为目的地、政府、景区、游客等提供基于全流程服务和管理的智慧文旅应用体系。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small10.svg" />
                    <span>智慧景区</span>
                    <p>
                      面向景区管理者和消费者，提供景区游客服务、景区智慧管理和智慧营销等一站式产品和服务能力，做景区数字化助手。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small11.svg" />
                    <span>智慧文博</span>
                    <p>
                      通过平台、技术、连接、内容，助力博物馆和文博产业，实现馆内管理与保护、文物价值创新、优化展馆体验、提升文化教育。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small12.svg" />
                    <span>智慧科技馆</span>
                    <p>
                      通过腾讯在科技领域的前沿探索和丰富实践，助力全国科普机构提升场馆建设效能、创新展教服务，做科技馆的数字化助手。
                    </p>
                  </li>
                </ul>
                <strong>—&emsp;标准产品&emsp;—</strong>
                <ul className={css.containOneUl}>
                  <li style={{ minHeight: 80 }}>
                    <span><b />目的地名片</span>
                    <p>
                      目的地名片是腾讯推出的目的地智慧旅游标准产品，帮助国内政企客户快速搭建官方站点、实现本地精彩内容推荐、游客指南，同时提供多种轻量级智能服务。
                    </p>
                  </li>
                  <li style={{ minHeight: 80 }}>
                    <span><b />城市行囊</span>
                    <p>
                      结合腾讯的社交分享、智能翻译、线上退税、电子卡券、社区运营等各个产品线的丰富能力，针对海外目的地场景，采用最轻量化的小程序形式，为目的地提供数字化助手和工具箱。
                    </p>
                  </li>
                  <li style={{ minHeight: 80 }}>
                    <span><b />智慧景区</span>
                    <p>
                      通过腾讯的数字化能力，整合互联网、物联网、大数据、人工智能等技术连接景区与游客；通过智能终端应用、硬件互联互通、后台管理大脑和游客行为分析等，提升景区整体服务效率，打造美好的游客体验。
                    </p>
                  </li>
                  <li style={{ minHeight: 80 }}>
                    <span><b />景区直播</span>
                    <p>
                      旅游直播平台为景区提供24小时不间断实景直播，及活动直播。慢直播方便用户通过观看直播了解景区的风景、人流量、天气等信息，为出行做参考；活动直播方便用户了解到景区的特色活动及大事件，提升景区品牌传播。
                    </p>
                  </li>
                </ul>
              </div>
            </>
          )}
          {current === 4 && (
            <>
              <div className={css.right1}>
                <strong>—&emsp;方案优势&emsp;—</strong>
                <ul className={css.containThreeUl}>
                  <li style={{ minHeight: 230 }}>
                    <img alt="" src="images/store01.svg" />
                    <span>高转化率</span>
                    <p>从渠道经营转换为消费者经营，实现消费者画像，提高转化率。</p>
                  </li>
                  <li style={{ minHeight: 230 }}>
                    <img alt="" src="images/store02.svg" />
                    <span>精准触达</span>
                    <p>精准触达，扩大客流，专注服务最终客户。</p>
                  </li>
                  <li style={{ minHeight: 230 }}>
                    <img alt="" src="images/store03.svg" />
                    <span>个性化定制</span>
                    <p>全渠道打通，满足个性化、多样化需求，巩固品牌。</p>
                  </li>
                </ul>
                <strong>—&emsp;解决方案&emsp;—</strong>
                <ul className={css.containUl}>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small13.svg" />
                    <span>智慧门店</span>
                    <p>
                      借助人工智能应用服务，打造客户 Face ID 体系，实现 VIP 识别、客群画像、热力图、动线分析、智能安防，助力企业优化商铺布局，打造优质商场服务。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small14.svg" />
                    <span>智慧营销</span>
                    <p>
                      借助大数据分析能力（如：LBS 系统）进行商圈分析，绘画客户画像，开发潜在客户，通过社交平台精准触达客户。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small15.svg" />
                    <span>智慧中台</span>
                    <p>
                      依托中间件能力，打造企业数据中台，打通数据孤岛，智能分析实时数据，提高企业管理效率。
                    </p>
                  </li>
                  <li style={{ minHeight: 120 }}>
                    <img alt="" src="images/small16.svg" />
                    <span>智慧支撑</span>
                    <p>
                    通过依托强大的计算能力，弹性应对营销、数据分析等活动的开展，为营销、数据分析等活动提供强有力的支撑。
                    </p>
                  </li>
                </ul>
                <strong>—&emsp;目标&emsp;—</strong>
                <ul className={css.containOneUl}>
                  <li style={{ minHeight: 30 }}>
                    <p>a. 从渠道经营转换为消费者经营，实现消费者画像，提高转化率，从而达到高转化率的目标。</p>
                  </li>
                  <li style={{ minHeight: 30 }}>
                    <p>b. 精准触达最终客户，扩大客流，通过提高服务质量触达最终客户，为客户提供极致的服务体验。</p>
                  </li>
                  <li style={{ minHeight: 30 }}>
                    <p>c. 全渠道打通，满足个性化、多样化需求，提高品牌用户粘性，丰富品牌个性，提高品牌竞争力，巩固品牌。</p>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
    </>
  );
}

export default Solution;
