import { Icon } from 'common/svg';
import { Location, Email, Telephone } from 'common/svg/icon';
// import { Link } from 'react-router-dom';
import css from './index.module.scss';

const Footer = () => (
  <footer>
    <div className={css.info}>
      <div className={css.address}>
        <img src="/images/logo2.svg" alt="logo" />
        <div style={{ marginTop: 23 }}>
          <Icon type={Location} width="16" height="20" viewBox="0 0 1024 1024" />
          <span>公司地址</span>
        </div>
        <ul>
          <li>贵阳地址：贵阳市高新区长岭南路178号茅台国际商务中心C栋2406号</li>
          <li>成都地址：四川省成都市高新区天府二街269号27栋21层2108号</li>
        </ul>
        <div>
          <Icon type={Email} width="25" height="20" viewBox="0 0 1024 1024" />
          <span>E-mail：tcf-hr@tcfuture.tech</span>
        </div>
        <div>
          <Icon type={Telephone} width="25" height="20" viewBox="0 0 1024 1024" />
          <span>电话：028-6018 8776</span>
        </div>
      </div>
      <div className={css.contact}>
        <ul>
          <li>
            <img src="/images/icon01.svg" title="专业服务团队" alt="专业服务团队" />
            <div className={css.footerText}>
              <span>专业服务团队</span>
              <p>专业服务团队，竭诚为您服务</p>
            </div>
          </li>
          <li>
            <img src="/images/icon02.svg" title="高效及时响应机制" alt="高效及时响应机制" />
            <div className={css.footerText}>
              <span>高效及时响应机制</span>
              <p>高素质团队7*24小时及时响应客户</p>
            </div>
          </li>
          <li>
            <img src="/images/icon03.svg" title="优质定制化服务" alt="优质定制化服务" />
            <div className={css.footerText}>
              <span>优质定制化服务</span>
              <p>为您提供专业优质的定制化服务</p>
            </div>
          </li>
          <li>
            <img src="/images/icon04.svg" title="成就客户" alt="成就客户" />
            <div className={css.footerText}>
              <span>成就客户</span>
              <p>服务宗旨:“诚信负责，成就客户”</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div className={css.copyright}>
      <a target="_blank" rel="noreferrer" href="https://beian.miit.gov.cn/" style={{ color: '#fff' }}>
        黔ICP备20002432号-2 云腾未来 版权所有
      </a>
      <br />
      公安备案：
      <a target="_blank" rel="noreferrer" href="http://www.beian.gov.cn/" style={{ color: '#fff' }}>
        <img src="/images/police.png" alt="" />贵公网安备 52011502001289号
      </a>
    </div>
    {/* <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52011502001042">
        黔ICP备20002432号-1 贵公网安备52011502001042号
      </a> */}
  </footer>
);

export default Footer;
