import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import css from './index.module.scss';

// let n = 0;
// let speed = 4000; //播放速度

const Banner = props => {
  const bannerRef = useRef();
  const ulRef = useRef();
  const imageRef = useRef();
  const [data] = useState([
    {
      0: '/',
      zIndex: 1,
      alt: '云腾未来 诚信负责 成就客户',
    },
    // {
    //   1: '/solution',
    //   zIndex: 0,
    //   alt: '力球最极致解决方案',
    // },
    // {
    //   2: '/products',
    //   zIndex: 0,
    //   alt: '腾讯云产品中心 打造最极致产品体验',
    // },
    // {
    //   3: '/employment',
    //   zIndex: 0,
    //   alt: '和一群有趣的人创造未来',
    // },
    // {
    //   4: '/about',
    //   zIndex: 0,
    //   alt: '腾讯云西区技术支撑中心',
    // },
  ]);
  // const [num, setNum] = useState(n);
  const obj = {
    '/solution': {
      src: 'banner1.png',
      alt: '力球最极致解决方案',
    },
    '/products': {
      src: 'banner2.png',
      alt: '腾讯云产品中心 打造最极致产品体验',
    },
    '/': {
      src: 'banner0.png',
      data,
    },
    '/employment': {
      src: 'banner3.png',
      alt: '和一群有趣的人创造未来',
    },
    '/about': {
      src: 'banner4.jpg',
      alt: '腾讯云西区技术支撑中心',
    },
  };

  useEffect(() => {
    let banner = bannerRef?.current;
    let ul = ulRef?.current;
    let li = ul?.children;
    let timer1 = null;
    // banner.style.height = '691px';    
    if (props.pathname === '/') {      
      timer1 = setTimeout(() => {
        banner.style.minHeight = li[0]?.offsetHeight + 'px';
        banner.style.height = li[0]?.offsetHeight + 'px';
        ul.style.height = li[0]?.offsetHeight + 'px';
      }, 100);
    } else {
      imageRef.current.onload = () => {
        timer1 = setTimeout(() => {
          banner.style.minHeight = imageRef.current.offsetHeight + 'px';
          banner.style.height = imageRef.current.offsetHeight + 'px';
        }, 300);
      }      
    }
    window.addEventListener('resize', resize, false);
    return () => {
      clearTimeout(timer1);
      // clearInterval(timer2);
      window.removeEventListener('resize', resize, false);
    };
  });

  const resize = () => {
    let banner = bannerRef?.current;
    let ul = ulRef?.current;
    let li = ul?.children;
    if (props.pathname === '/') {
      banner.style.minHeight = li[0]?.offsetHeight + 'px';
      banner.style.height = li[0]?.offsetHeight + 'px';
      ul.style.height = li[0]?.offsetHeight + 'px';
    } else {
      imageRef.current.onload = () => {
        banner.style.minHeight = imageRef.current.offsetHeight + 'px';
        banner.style.height = imageRef.current.offsetHeight + 'px';
      }
    }
  };

  return (
    <div className={css.banner} ref={bannerRef} style={{
      marginTop: props.pathname === '/about' ? -163 : 0,
      zIndex: props.pathname === '/about' ? -1 : 0,
      // width: props.pathname === '/about' ? '100vw' : '100%',
    }}>
      {props.pathname === '/' ? (
        <>
          <ul className={css.list} ref={ulRef}>
            {data.map((item, index) => (
              <li key={index} style={{ zIndex: item.zIndex }}>
                <Link to={item[index]}>
                  <img
                    src={`/images/banner${[index]}.png`}
                    width="100%"
                    alt={item.alt}
                    className={item.zIndex === 1 ? css.active : ''}
                  />
                </Link>
              </li>
            ))}
          </ul>
          {/* <ul className={css.point}>
            {data.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  changeBanner(index);
                  setNum(index);
                }}
                className={index === num ? css.selectd : ''}
              ></li>
            ))}
          </ul> */}
        </>
      ) : (
        <>
          <img ref={imageRef} src={`/images/${obj[props.pathname].src}`} width="100%" alt={`${obj[props.pathname].alt}`} />
          {props.pathname === '/about' &&
              <p className={css.desc}>
                贵州云腾未来科技有限公司是腾讯云西区技术支撑中心，是腾讯云全国四大支撑中心之一。<br />
                云腾未来公司总部位于贵阳高新区，并在成都、重庆、昆明、西安等设立分支机构。公司的主要业务范围为腾讯云全系列产<br />
                品及解决方案售前、交付、开发与售后维护。涉及云计算、大数据等产品和服务，以及腾讯云解决方案在政府、金融、文<br />
                旅、零售等八大重要行业的拓展销售。腾讯云是腾讯集团产业互联网战略的主要执行者，业务增长迅猛，发展空间巨大。云<br />
                腾未来公司作为腾讯云在西部地区最核心的技术支持力量，得到贵阳市政府和高新区的关注和大力支持，处于高速成长中，<br />
                目前拥有近500人专业服务团队，覆盖西部地区十省业务。
              </p>
          }          
        </>
      )}
    </div>
  );
};

export default Banner;
