import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import css from './index.module.scss';

function Home() {
  let history = useHistory();
  // const [current, setCurrent] = useState(0);

  // const switchContent = (event, num) => {
  //   event.stopPropagation();
  //   if (num !== undefined) {
  //     setCurrent(num);
  //   }
  // };
  const switchhistory = (event, link) => {
    event.stopPropagation();
    history.push(link);
  };
  // const solution = [
  //   '以提升消费者体验为核心，提高数字化工具串联快消品类在零售过程中涉及的各个环节，提升快消行业的效率，降低快消品类的流通成本。',
  //   '为金融客户量身定制合规安全、低成本、高性能、高可用的云服务，助力客户科技升级，打造云上金融，智创未来。',
  //   '依托腾讯云开放的技术与海量数据运营的经验，全面打造“互联网+政务”解决方案，创新政府管理和服务模式，提升“放管服”实效。',
  //   '聚焦科技+文化+旅游的融合创新及落地应用，提供智慧文旅场景的解决方案和标准产品，通过数字化技术推动文旅产业转型升级。',
  // ];

  return (
    <Fragment>
      <div style={{ background: `url('/images/index_bg01.png') 100% 100px no-repeat #fff`, backgroundSize: 'auto 100%' }}>
        <div className={css.item1}>
          <img src="/images/index_companyadvan.png" width="100%" alt="" />
          <div className={css.right}>
            <img src="/images/index_pic01.png" alt="" />
            <strong>企业优势</strong>
            <p>
              腾讯战略投资，作为腾讯云西部地区业务和技术支撑中心，拥有一支所向披靡的技术团队，存在一个精英备至的领导班子，怀抱一群有梦想的中青年，正在云端乘风波浪，创造属于中国西部的腾云梦想。
            </p>
          </div>
        </div>
        <div
          className={css.item2}
        >
        <div className={css.top}>
          <div className={css.containItem}>
            <strong>解决方案<span onClick={event => switchhistory(event, '/solution')}>
              查看更多<img src="/images/right-arrow.svg" alt="" />
            </span></strong>
            <div className={css.text}>
              <ul>
                <li>区域医疗
                  <p>
                  依托腾讯云优势，联合合作伙伴，连接政府、医疗服务机构、医疗研发与流通、康养等，构建医疗大健康产业云生态，助力数字化升级。
                  </p>
                </li>
                <li>智慧文旅
                  <p>
                  聚焦科技+文化+旅游的融合创新及落地应用，提供智慧文旅场景的解决方案和标准产品，通过数字化技术推动文旅产业转型升级。
                  </p>
                </li>
                <li>智慧零售
                  <p>
                  以提升消费者体验为核心，提高数字化工具串联零售品类在零售过程中涉及的各个环节，提升零售行业的效率，降低零售品类的流通成本。
                  </p>
                </li>
              </ul>
              <ul>
                <li>智慧金融
                  <p>
                  为金融客户量身定制合规安全、低成本、高性能、高可用的云服务，助力客户科技升级，打造云上金融，智创未来。
                  </p>
                </li>
                <li>数字政务
                  <p>
                  依托腾讯云开放的技术与海量数据运营的经验，全面打造“互联网+政务”解决方案，创新政府管理和服务模式，提升“放管服”实效。
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className={css.containImage}>
            <img src="/images/index_pic02.png" alt="" />
            <img src="/images/index_solution.png" alt="" width="100%" />
            <img src="/images/turn-show.png" alt="" />
          </div>
        </div>
        <div className={css.bottom}>
          <div className={css.containImage}>
            <img src="/images/index_bg04.png" alt="" />
            <img src="/images/index_bg05.png" alt="" />
            <img src="/images/index_product.png" alt="" width="100%" />
          </div>
          <div>
            <strong>产品展示<span onClick={event => switchhistory(event, '/products')}>
              查看更多<img src="/images/right-arrow.svg" alt="" />
            </span></strong>
            <div className={css.list}>
              <ul>
                <li>
                  腾讯微服务平台
                  <p>腾讯微服务平台致力于提供一站式服务全生命周期管理能力和数据化运营支持，提供多维度应用、服务、机器的监控数据，助力服务性能优化。</p>
                </li>
                <li>
                  腾讯云TCE
                  <p>腾讯云TCE（Tencent Cloud Enterprise）是基于腾讯云成熟产品体系的企业级专有云平台，为企业提供自主可控、弹性伸缩的全栈服务能力。</p>
                </li>
                <li>
                  腾讯云Tstack
                  <p>腾讯云 TStack 是企业级私有化全栈云解决方案，提供满足国内主流架构的 IaaS、PaaS 与 SaaS 综合云服务，多种部署方式，灵活可控。</p>
                </li>
              </ul>
              <ul>
                <li>
                  腾讯分布式数据库
                  <p>分布式数据库具备强一致高可用、全球部署架构、高 SQL 兼容度、分布式水平扩展、高性能、完整的分布式事务支持、企业级安全等特性。</p>
                </li>
                <li>
                  腾讯大数据处理套件TBDS
                  <p>大数据处理套件TBDS是基于腾讯多年海量数据处理经验，对外提供的可靠、安全、易用的大数据处理平台。</p>
                </li>
                <li>
                  腾讯云数据库
                  <p>腾讯云数据库提供了备份回档、监控、快速扩容、数据传输等数据库运维全套解决方案，为您简化 IT 运维工作，让您能更加专注于业务发展。</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
      {/* 合作客户 */}
      <div className={css.operationOuter}>
        <div className={css.cooperation}>
          <strong>—&ensp;合作客户&ensp;—</strong>
          <ul>
            <li><img src="/images/partners/guiyanggov.png" title="贵阳市人民政府" alt="贵阳市人民政府" /></li>
            <li><img src="/images/partners/guizhoubank.png" title="贵州银行" alt="贵州银行" /></li>
            <li><img src="/images/partners/jiannanchun.png" title="剑南春" alt="剑南春" /></li>
            <li><img src="/images/partners/jinpai.png" title="劲牌" alt="劲牌" /></li>
            <li><img src="/images/partners/healthcode.png" title="四川天府健康通" alt="四川天府健康通" /></li>
          </ul>
          <br />
          <ul>
            <li><img src="/images/partners/shuijingfang.png" title="水井坊" alt="水井坊" /></li>
            <li><img src="/images/partners/sportsLottery.png" title="中国体育彩票" alt="中国体育彩票" /></li>
            <li><img src="/images/partners/rongyiban.png" title="天府蓉易办" alt="天府蓉易办" /></li>
            <li><img src="/images/partners/wuliangtastes.png" title="五粮浓香" alt="五粮浓香" /></li>
            <li><img src="/images/partners/yunnanpolice.png" title="云南省公安厅" alt="云南省公安厅" /></li>
          </ul>
        </div>
      </div>      
    </Fragment>
  );
}

export default Home;
