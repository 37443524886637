import { Fragment } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom';
import Header from 'common/header';
import Banner from 'common/banner';
import Footer from 'common/footer';
import Home from 'pages/home';
import Solution from 'pages/solution';
import Products from 'pages/products';
import Employment from 'pages/employment';
import About from 'pages/about';
import NotFound from 'pages/404';
// import Blank from 'common/blank';

function Layout(props) {
  // console.log('location', props);
  // const { location: { pathname } } = props;
  // const onChange = () => {
  //   const width = document.documentElement.clientWidth;
  //   const raito = parseFloat(width / 1920).toFixed(4);
  //   document.getElementsByTagName("body")[0].style.setProperty("zoom", raito > 1 ? 1 : raito);
  //   document.getElementById("root").style.position = `relative`;
  // }
  
  // useEffect(() => {
  //   // window.addEventListener('resize', onChange, false);
  //   // onChange();
    
  //   return () => {
  //     window.removeEventListener('resize', onChange, false)
  //   }
  // }, [])
  return (
    <Fragment>
      <Header />
      <Banner pathname={props.location.pathname} />
      <Switch>
        <Route path="/" exact={true} component={Home} />
        <Route path="/solution" component={Solution} />
        <Route path="/products" component={Products} />
        <Route path="/employment" component={Employment} />
        <Route path="/about" component={About} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      {/* {(pathname === '/solution') &&
        <Blank />
      } */}
    </Fragment>
  );
}

export default withRouter(Layout);
