import React, { FunctionComponent } from 'react';

interface Props {
  type: FunctionComponent;
  width?: number | string;
  height?: number | string;
  color?: string;
  viewBox?: string;
}

function Icon(props: Props) {
  const Cmp = props.type;
  return (
    <svg
      style={{
        verticalAlign: 'middle',
        fill: 'currentColor',
        color: props.color,
      }}
      width={props.width || 20}
      height={props.height || 20}
      viewBox={props.viewBox || '0 0 19 20'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Cmp />
    </svg>
  );
}

export { Icon };
