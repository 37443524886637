import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './base.scss';
import Layout from 'common/layout';
ReactDOM.render(
  <Router>
    <Layout />
  </Router>,
  document.getElementById('root')
);
