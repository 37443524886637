import { useState, useEffect } from 'react';
// import { PopConfirm } from "@tencent/tea-component";
import { Icon } from 'common/svg';
import {
  Product0,
  Product1,
  Product2,
  Product3,
  Product4,
  Product5,
  Scene0,
  Scene1,
  Scene2,
  Scene3,
} from 'common/svg/icon';

import css from './index.module.scss';
// import './pop.css';

function Products() {
  // const [showModal, setShowModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const [item, setItem] = useState('实时高并发事务系统');
  const [item1, setItem1] = useState('部署方式');
  const [item2, setItem2] = useState('构建分布式系统');
  const [item3, setItem3] = useState('金融领域');
  const [item4, setItem4] = useState('数据仓库构建');
  const [item5, setItem5] = useState('行业版');
  useEffect(() => {
    document.addEventListener('click', hiddenModal, false);
    return () => {
      document.removeEventListener('click', hiddenModal, false);
    };
  });
  const hiddenModal = () => {
    // setShowModal(false);
  };
  const distributedDatabaseScene = {}
  const tencentCloudTask = {}
  const microServicePlatform = {}
  const tencentCloudDatabase = {}
  const bigdataProcessingSuite = {}
  const tencentCloudTCE = {}
  distributedDatabaseScene['实时高并发事务系统'] = {
    desc: '互联网、移动互联网、电商等业务的蓬勃发展，使客户量不断增长，业务核心系统需经受高并发访问的考验。TDSQL 的 share nothing 架构可以在线平滑地扩展实例规模，从容应对此类场景；同时，TDSQL 提供的分布式事务一致性能力能保证高并发场景下的分布式系统事务一致性。',
    img: "/images/scene01.png",
  };
  distributedDatabaseScene['金融级核心交易系统'] = {
    desc: '随着手机银行、网上理财、区块链等等具有互联网特色的金融业务兴起，单笔交易变小，交易次数变多等情况；传统架构逐渐不足以支撑业务发展，网络安全风险逐渐增加； TDSQL 不仅在性能容易扩展，强同步能力也确保数据不错不丢，是国内第一个将分布式事务应用于金融系统的产品，同时支持部署在腾讯金融云，支持物理独享，加密，审计等系列安全方案。',
    img: "/images/scene02.png",
  };
  distributedDatabaseScene['HTAP业务系统'] = {
    desc: '在数据库系统应用中，OLTP 场景涉及数据量小，但对返回实时性要求高，OLAP 类场景涉及的数据量和计算量大，但是对实时性要求不高，一套系统里能同时覆盖 OLTP 以及 OLAP 场景是业务对数据库系统的一个很强烈的诉求。TDSQL 特有的架构设计，可同时覆盖 OLTP 和 OLAP 的需求。',
    img: "/images/scene03.png",
  };
  distributedDatabaseScene['物联网类应用系统'] = {
    desc: 'TDSQL MySQL 版支持 JSON 让开发者用自己熟悉的协议开发系统；同时可扩展 RocksDB，让数据压缩率低至20%以下，二级分区让冷热数据有效快速的分离处理，极大的降低了数据存储成本和数据处理效率。PostgreSQL 版支持最先进的开源地理信息引擎 PostGIS，结合位置信息和已有的地理信息进行关联分析，可以快速得到具有分析价值的数据结果。',
    img: "/images/scene04.png",
  };
  tencentCloudTask['部署方式'] = {
    desc: '腾讯云TStack四大部署方式，主要包括：本地化、一体机柜、黑石、腾讯云VPC。',
    img: "/images/scene05.png",
  };
  tencentCloudTask['入云标准'] = {
    desc: '高效务实地帮助政企构建并管理私有云和混合云的解决方案，助力政企平滑地向基于云计算的IT基础架构转型。',
    img: "/images/scene06.png",
  };
  tencentCloudTask['服务运营体系'] = {
    desc: '基于腾讯多年的运营经验，构建了完善的“1个运营保障+2级运营服务+3级热线服务”的运营体系，助力用户建设云生态。',
    img: "/images/scene07.png",
  };
  microServicePlatform['构建分布式系统'] = {
    desc: '金融业务通常有严格的合规性要求，用户能够将业务部署在专用宿主机的云服务器上，在资源共享的同时保证与其他用户的子机物理隔离，满足敏感业务数据保护、磁盘消磁需求。',
    img: "/images/scene08.png",
  };
  microServicePlatform['应用发布和管理'] = {
    desc: 'TSF 针对分布式系统的应用发布和管理，提供了简单易用的可视化控制台。用户通过控制台可以发布应用，包括创建、部署、启动应用，也支持查看应用的部署状态。除此之外，用户可以通过控制台管理应用，包括回滚应用、扩容、缩容和删除应用。',
    img: "/images/scene09.png",
  };
  microServicePlatform['数据化运营'] = {
    desc: '通过对日志埋点的收集和分析，可以得到一次请求在各个服务间的调用链关系，有助于梳理应用的请求入口与服务的调用来源、依赖关系。当遇到请求耗时较长的情况，可以通过调用链分析调用瓶颈，快速定位异常。',
    img: "/images/scene10.png",
  };
  microServicePlatform['服务治理'] = {
    desc: '支持服务级别和 API 级别的服务治理能力，包括服务路由、服务限流、服务鉴权功能。服务路由功能支持将请求按权重路由到不同版本的服务上。',
    img: "/images/scene11.png",
  };
  tencentCloudDatabase['金融领域'] = {
    desc: '联合腾讯云金融云解决方案，云数据库 MariaDB 在多个项目中，通过银行、保险相关监管单位信息安全检查，且在核心系统中替代 Oracle 数据库，用于存储和处理金融交易数据、账户数据。并且，云数据库为您提供安全审计，跨地域容灾，数据强一致的数据库服务，保证您的金融数据安全和高可靠。',
    img: "/images/scene12.png",
  };
  tencentCloudDatabase['电商领域'] = {
    desc: '稳定应对高并发流量，轻松迎接突发业务高峰，云数据库 MariaDB 高性能特性以及 Redis 快速读写能力帮你在活动大促时解决访问高峰带来的请求压力。',
    img: "/images/scene13.png",
  };
  tencentCloudDatabase['互联网/移动APP领域'] = {
    desc: '云数据库 MariaDB 在互联网/移动 APP 中作为服务端最终数据落地存储介质，针对行业读多写少的场景，可将热点库增加只读实例，大幅提升读取能力。',
    img: "/images/scene14.png",
  };
  tencentCloudDatabase['游戏领域'] = {
    desc: '游戏等需要弹性扩容和快速回档的业务。云数据库 MariaDB 对计算资源的弹性伸缩能力，赋予您更高的生产力，分钟级部署游戏分区数据库。借助 MariaDB 任意时间点回档功能及支持批量操作的特性，您可以随时随地恢复到任意时间点，为游戏回档提供支持。',
    img: "/images/scene15.png",
  };
  bigdataProcessingSuite['数据仓库构建'] = {
    desc: '大数据处理套件完整覆盖数据抽取、转换、加载、建模、分析、报表呈现、数据治理等数仓建设环节，用户可借助大数据套件在公有云、私有云、非云化环境快速建设 TB 到 PB 级的企业数据仓库和数据集市，搭建专属的大数据应用。',
    img: "/images/scene16.png",
  };
  bigdataProcessingSuite['实时流式数据处理'] = {
    desc: '流式数据处理可用于金融行业的风险管控、物联网的海量传感器数据处理、工业生产线的实时故障预警、病人特征数据实时分析、实时交通流量分析、互联网实时流量分析等应用场景。',
    img: "/images/scene17.png",
  };
  bigdataProcessingSuite['数据探索挖掘'] = {
    desc: '通过腾讯大数据处理套件所提供的强大数据分析与探索挖掘能力，用户可快速对企业在 PB 级规模下的大数据进行可视化的数据分析探索，在纷繁复杂的商业数据中快速获取数据洞察力，占领商业先机。',
    img: "/images/scene18.png",
  };
  tencentCloudTCE['行业版'] = {
    desc: '基于腾讯云完整产品体系输出的全栈云平台。提供 IaaS/PaaS/SaaS 全量云产品矩阵，满足企业的私有化部署、自主可控的需求，降低技术难度及运维复杂性，为客户提供高水平、易运维、智能化的云服务。',
    img: "/images/scene19.png",
  };
  tencentCloudTCE['企业版'] = {
    desc: '基于腾讯云完整产品体系输出的全栈云平台。提供 IaaS/PaaS/SaaS 全量云产品矩阵，满足企业的私有化部署、自主可控的需求，降低技术难度及运维复杂性，为客户提供高水平、易运维、智能化的云服务。',
    img: "/images/scene20.png",
  };
  tencentCloudTCE['大数据版'] = {
    desc: '基于腾讯多年海量数据处理经验推出的一站式大数据解决方案，包含大数据平台、商业分析、数据可视化等产品，支持私有化部署，让企业快速具备整合、分析、处理海量数据的能力。',
    img: "/images/scene21.png",
  };
  tencentCloudTCE['AI版'] = {
    desc: '基于腾讯超大规模机器学习平台和优图实验室世界领先的深度学习技术所推出的私有化人工智能解决方案，覆盖图像、语音、自然语言等 AI 领域应用，助力企业提升行业竞争力。',
    img: "/images/scene22.png",
  };
  tencentCloudTCE['敏捷版'] = {
    desc: '基于容器技术，以 DevOps 为理念，面向微服务应用的新一代 PaaS 云平台。提供基于 Spring Cloud 深度优化的分布式服务框架、兼容 Mysql 协议的分布式数据库 TDSQL，帮助客户快速构建轻量化云平台。',
    img: "/images/scene23.png",
  };
  const setBlankTop = (par, height) => {
    document.querySelector('div[set="setTop"]').style.top = `${par}px`;
    document.querySelector('div[set="setTop"]').style.height = `${height}px`;
  }

  return (
    <>
      <div className={css.line}></div>
      <div className={css.tab}>
      <div className={css.blank} set="setTop"></div>
      <ul className={css.nav}>
        <li
          onMouseOver={() => {
            setCurrent(0);
            setBlankTop(598, 800);
          }}
          className={current === 0 ? css.selectd : ''}
        >
          <Icon type={Product0} width="19" height="20" />分布式数据库
        </li>
        <li
          onMouseOver={() => {
            setCurrent(1);
            setBlankTop(588, 1100);
          }}
          className={current === 1 ? css.selectd : ''}
        >
          <Icon type={Product1} width="21" height="20" />腾讯云TStack
        </li>
        <li
          onMouseOver={() => {
            setCurrent(2);
            setBlankTop(588, 1300);
          }}
          className={current === 2 ? css.selectd : ''}
        >
          <Icon type={Product2} width="20" height="18" />腾讯微服务平台
        </li>
        <li
          onMouseOver={() => {
            setCurrent(3);
            setBlankTop(598, 900);
          }}
          className={current === 3 ? css.selectd : ''}
        >
          <Icon type={Product3} width="19" height="19" />腾讯云数据库
        </li>
        <li
          onMouseOver={() => {
            setCurrent(4);
            setBlankTop(598, 1200);
          }}
          className={current === 4 ? css.selectd : ''}
        >
          <Icon type={Product4} width="22" height="16" />大数据处理套件
        </li>
        <li
          onMouseOver={() => {
            setCurrent(5);
            setBlankTop(593, 1450);
          }}
          className={current === 5 ? css.selectd : ''}
        >
          <Icon type={Product5} width="21" height="19" />腾讯云TCE
        </li>
      </ul>
      <div className={css.content}>
        <div className={css.item}>
          <img className={css.image02} src="/images/index_bg04.png" alt="" />
          <img className={css.image01} src="/images/index_pic01.png" alt="" />
          {current === 0 && (
            <>
              <div className={css.right}>
                <strong>—&emsp;产品优势&emsp;—</strong>
                <ul className={css.containThreeUl}>
                  <li>
                    <span><b />强同步复制</span>
                    <p>
                      系统支持强同步复制以提供数据强一致，业务系统写入数据后，只有当数据库从机同步后才给予应用事务应答，确保主从数据完全一致，不会因故障导致数据丢失、错乱，且强同步复制性能已基本等于异步复制。
                    </p>
                  </li>
                  <li>
                    <span><b />超高性能</span>
                    <p>
                    深度定制开发数据库内核，性能远超于同引擎的开源产品；支持读写分离，有效提供读扩展的同时提供开发灵活性；对数据库连接分配逻辑进行了深度优化，在重负载时表现更佳。
                    </p>
                  </li>
                  <li>
                    <span><b />企业级数据安全</span>
                    <p>
                    支持一主多从能力，数据强同步一致性。支持三权分立的体系，并能够提供数据透明加密，数据脱敏访问，强制访问控制等多个层级的数据安全保障能力。
                    </p>
                  </li>
                  <li>
                    <span><b />支持MySQL引擎</span>
                    <p>
                    100%兼容 MySQL 协议，并支持原生的 JSON 类型，可以将 TDSQL 看做一个兼容 MySQL 协议的 数据库。 并在此基础之上还支持分布式特性 。
                    </p>
                  </li>
                  <li>
                    <span><b />不停机弹性扩展</span>
                    <p>
                    当数据库性能或容量不足以支撑业务发展时，在控制台点击，即可自动升级完成。升级过程中，您无需关心分布式系统内的数据迁移，均衡和路由切换。
                    </p>
                  </li>
                  <li>
                    <span><b />领先的分布式架构</span>
                    <p>
                    通过提供（分布式）事务特性，提供全局唯一数字序列，支持 JSON 等能力，为开发者提供灵活的开发方案。通过自主专利的分布式事务一致性技术来保证在全分布式环境下的事务一致性。 
                    </p>
                  </li>
                </ul>
                <strong style={{ marginTop: 93 }}>—&emsp;应用场景&emsp;—</strong>                
                <ul className={css.newNav}>
                  <li
                    onMouseOver={() => {
                      setItem('实时高并发事务系统');
                    }}
                    className={item === '实时高并发事务系统' ? css.selectd : ''}
                  >
                    <Icon type={Scene0} width="20" height="20" />实时高并发事务系统
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem('金融级核心交易系统');
                    }}
                    className={item === '金融级核心交易系统' ? css.selectd : ''}
                  >
                    <Icon type={Scene1} width="21" height="19" />金融级核心交易系统
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem('HTAP业务系统');
                    }}
                    className={item === 'HTAP业务系统' ? css.selectd : ''}
                  >
                    <Icon type={Scene2} width="18" height="17" />HTAP业务系统
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem('物联网类应用系统');
                    }}
                    className={item === '物联网类应用系统' ? css.selectd : ''}
                  >
                    <Icon type={Scene3} width="22" height="20" />物联网类应用系统
                  </li>
                </ul>
                <div className={css.containScene}>
                  <p>{distributedDatabaseScene[item].desc}</p>
                  <img alt={distributedDatabaseScene[item].desc} src={distributedDatabaseScene[item].img} />
                </div>
              </div>
            </>
          )}
          {current === 1 && (
            <>
              <div className={css.right}>
                <strong>—&emsp;产品优势&emsp;—</strong>
                <ul className={css.containThreeUl}>
                  <li>
                    <span><b />海量的服务经验</span>
                    <p>
                    应用于腾讯内部IT服务，私有云拥有12000+主机，服务于腾讯公司4万用户以及集团公司数十万用户，稳定运行时间超过8年。
                    </p>
                  </li>
                  <li>
                    <span><b />丰富的产品体系</span>
                    <p>
                    接入大量腾讯内部成熟的PaaS和SaaS服务，例如大数据、AI等；全面适配和兼容国内主流应用，便于客户根据需求完善应用服务体系。
                    </p>
                  </li>
                  <li>
                    <span><b />高效的运管能力</span>
                    <p>
                    模块化设计，独立部署，大屏监控显示，可视化操作，三级运维体系，保障用户系统的稳定高效运营。
                    </p>
                  </li>
                  <li>
                    <span><b />多云管理能力</span>
                    <p>
                    提供全方位混合云架构，与腾讯公有云完美融合；通过统一平台纳管多种芯片的异构资源，实现真正的一云多芯。
                    </p>
                  </li>
                  <li>
                    <span><b />专属腾讯云服务</span>
                    <p>
                    向下兼容异构硬件，屏蔽差异；向上提供云服务，池化资源；简化基础架构，缩短交付周期；提高开发效率，降低运管成本。
                    </p>
                  </li>
                </ul>
                <strong style={{ marginTop: 90 }}>—&emsp;交付运营体系&emsp;—</strong>
                <ul className={css.newNav}>
                  <li
                    onMouseOver={() => {
                      setItem1('部署方式');
                    }}
                    className={item1 === '部署方式' ? css.selectd : ''}
                  >
                    部署方式
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem1('入云标准');
                    }}
                    className={item1 === '入云标准' ? css.selectd : ''}
                  >
                    入云标准
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem1('服务运营体系');
                    }}
                    className={item1 === '服务运营体系' ? css.selectd : ''}
                  >
                    服务运营体系
                  </li>
                </ul>
                <div className={css.containScene}>
                  <p>{tencentCloudTask[item1].desc}</p>
                  <img alt={tencentCloudTask[item1].desc} src={tencentCloudTask[item1].img} />
                </div>
              </div>
            </>
          )}
          {current === 2 && (
            <>
              <div className={css.right}>
                <strong>—&emsp;产品优势&emsp;—</strong>
                <ul className={css.containThreeUl}>
                  <li>
                    <span><b />拥抱开源社区</span>
                    <p>
                    拥抱 Spring Cloud 和 Istio 开源社区，提供高可用、可扩展、灵活的微服务技术中台商业版支持，支持异构系统平滑迁移到 TSF 上，降低用户迁移到微服务的时间和人力成本。
                    </p>
                  </li>
                  <li>
                    <span><b />应用生命周期管理</span>
                    <p>
                    提供从创建应用到运行应用的全生命周期管理，支持创建、部署、回滚、扩容、下线、启动和停止应用。提供虚拟机和容器两种部署方式，满足不同客户的使用需求。
                    </p>
                  </li>
                  <li>
                    <span><b />细粒度服务治理</span>
                    <p>
                    提供服务和 API 级别的服务治理能力，支持控制台上配置服务路由、服务限流、服务鉴权规则，支持分布式配置管理。
                    </p>
                  </li>
                  <li>
                    <span><b />分布式事务</span>
                    <p>
                    集成了分布式事务能力，支持 TCC 模式分布式事务管理功能，解决跨数据库和跨服务的事务问题。
                    </p>
                  </li>
                  <li>
                    <span><b />灵活运维</span>
                    <p>
                    支持日志服务、调用链、服务依赖拓扑图、基于监控的弹性伸缩功能，满足不同纬度的运维需求。
                    </p>
                  </li>
                  <li>
                    <span><b />跨可用区高可用</span>
                    <p>
                    支持同城跨可用区容灾和就近路由，规避单可用区可能存在的不可抗力风险，提高服务的高可用性和容灾能力。
                    </p>
                  </li>
                </ul>
                <strong style={{ marginTop: 80 }}>—&emsp;应用场景&emsp;—</strong>
                <ul className={css.newNav}>
                  <li
                    onMouseOver={() => {
                      setItem2('构建分布式系统');
                    }}
                    className={item2 === '构建分布式系统' ? css.selectd : ''}
                  >
                    <Icon type={Scene0} width="20" height="20" />构建分布式系统
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem2('应用发布和管理');
                    }}
                    className={item2 === '应用发布和管理' ? css.selectd : ''}
                  >
                    <Icon type={Scene1} width="21" height="19" />应用发布和管理
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem2('数据化运营');
                    }}
                    className={item2 === '数据化运营' ? css.selectd : ''}
                  >
                    <Icon type={Scene2} width="18" height="17" />数据化运营
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem2('服务治理');
                    }}
                    className={item2 === '服务治理' ? css.selectd : ''}
                  >
                    <Icon type={Scene3} width="22" height="20" />服务治理
                  </li>
                </ul>
                <div className={css.containScene}>
                  <p>{microServicePlatform[item2].desc}</p>
                  <img alt={microServicePlatform[item2].desc} src={microServicePlatform[item2].img} />
                </div>
              </div>
            </>
          )}
          {current === 3 && (
            <>
              <div className={css.right1}>
                <strong>—&emsp;产品优势&emsp;—</strong>
                <ul className={css.containThreeUl}>
                  <li style={{ height: 190 }}>
                    <span><b />基于数据强一致的高可用</span>
                    <p>
                    默认采用主从架构，可确保95% 以上可用性；系统支持强同步复制以提供数据强一致，确保主从数据完全一致，不会因故障导致数据丢失、错乱，目前强同步复制性能已基本等于异步复制。
                    </p>
                  </li>
                  <li style={{ height: 190 }}>
                    <span><b />公有云&专有云部署</span>
                    <p>
                    在公有云上，您无需关心 MariaDB 的安装、部署、版本更新及故障处理。如果您期望在自有机房中部署兼容 MySQL / MariaDB 的云数据库或分布式数据库，您可以选择联系您的直属商务、架构师，购买 TDSQL 的专有云版本。
                    </p>
                  </li>
                  <li style={{ height: 190 }}>
                    <span><b />数据迁移</span>
                    <p>
                    借助数据传输服务 DTS，云服务器上的自建 MySQL 数据库到 MariaDB 数据库实例、具有外网 IP 的 IDC 机房内/其他友商云 MySQL 数据库到 MariaDB 数据库实例等多种场景可轻松进行数据库自动迁移，无需手动迁移数据库，实现业务无缝过渡上云。
                    </p>
                  </li>
                  <li>
                    <span><b />超高性能与读写分离</span>
                    <p>
                    云数据库 MariaDB 深度定制的内核，性能远超开源 MySQL，提供业界领先的吞吐能力；默认支持线程池，对存储引擎进行了大量优化，越是重负载，表现越佳。并可通过读写分离扩展性能，而基于只读账号的读写分离，可以根据不同需求配置不同的权限和策略。 
                    </p>
                  </li>
                  <li>
                    <span><b />数据迁移</span>
                    <p>
                    借助数据传输服务 DTS，云服务器上的自建 MySQL 数据库到 MariaDB 数据库实例等多种场景，可轻松进行数据库自动迁移，极大简化您的数据库上云工作，无需手动迁移数据库，实现业务无缝过渡上云。
                    </p>
                  </li>
                  <li>
                    <span><b />同城双活架构</span>
                    <p>
                    数据库主从节点跨可用区部署，两个可用区的服务器均通过唯一虚拟 IP 访问数据库。如果发生机房级故障，数据库节点将自动切换，且不会更改数据库访问 IP/ 端口，您可以借此架构搭建业务级同城双中心双活能力。
                    </p>
                  </li>
                </ul>
                <strong style={{ marginTop: 66 }}>—&emsp;应用场景&emsp;—</strong>
                <ul className={css.newNav}>
                  <li
                    onMouseOver={() => {
                      setItem3('金融领域');
                    }}
                    className={item3 === '金融领域' ? css.selectd : ''}
                  >
                    <Icon type={Scene0} width="20" height="20" />金融领域
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem3('电商领域');
                    }}
                    className={item3 === '电商领域' ? css.selectd : ''}
                  >
                    <Icon type={Scene1} width="21" height="19" />电商领域
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem3('互联网/移动APP领域');
                    }}
                    className={item3 === '互联网/移动APP领域' ? css.selectd : ''}
                  >
                    <Icon type={Scene2} width="18" height="17" />互联网/移动APP领域
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem3('游戏领域');
                    }}
                    className={item3 === '游戏领域' ? css.selectd : ''}
                  >
                    <Icon type={Scene3} width="22" height="20" />游戏领域
                  </li>
                </ul>
                <div className={css.containScene}>
                  <p>{tencentCloudDatabase[item3].desc}</p>
                  <img alt={tencentCloudDatabase[item3].desc} src={tencentCloudDatabase[item3].img} />
                </div>
              </div>
            </>
          )}
          {current === 4 && (
            <>
              <div className={css.right2}>
                <strong>—&emsp;产品优势&emsp;—</strong>
                <ul className={css.containThreeUl}>
                  <li style={{minHeight: 190}}>
                    <span><b />技术开放</span>
                    <p>
                    存储标准兼容开源 Hadoop 标准，使历史构建在 hadoop 上的大数据平台可以平滑迁移。支持多驱动接入、完美兼容社区标准；支持 Sql2003 标准的内存迭代运算引擎 SparkSQL；可选购 PLSQL 语法驱动，以获得90%的 Oracle 语法支持。
                    </p>
                  </li>
                  <li style={{minHeight: 190}}>
                    <span><b />安全可靠</span>
                    <p>
                    所有系统控制节点主从热备，故障秒级切换，腾讯95%业务考验，可用性99.999%。支持数据加密传输、存储。全平台单点登录，统一策略管控中心。基于角色的数据管控体系，支持列级粒度权限控制。完善的访问审计及预警模型。
                    </p>
                  </li>
                  <li style={{minHeight: 190}}>
                    <span><b />性能卓越</span>
                    <p>
                    高性能数据接入引擎，内部业务日接入五万亿条数据。性能全面超越社区方案，数据处理能力提升30%左右。支持上千维度、千亿规模数据的秒级交互式多维分析。
                    </p>
                  </li>
                  <li>
                    <span><b />场景丰富</span>
                    <p>
                    在金融、政务、公安、零售、传统企业等领域积累了丰富的业务应用案例；特别是在 PB 级离线、近线、实时数仓企业级用户画像，金融实时风控，精准推荐，物联网大数据等场景我们都有成熟的解决方案。
                    </p>
                  </li>
                  <li>
                    <span><b />简单易用</span>
                    <p>
                    支持一键式部署，只需选择您适合的服务即可快速完成部署；支持数据接入、处理、存储、分析、机器学习的拖拽式全链路大数据开发，开箱即用的数据治理工具集，数据开发者只需专注于业务开发。
                    </p>
                  </li>
                  <li>
                    <span><b />生态完善</span>
                    <p>
                    深度与各行业优秀的大数据开发、数据治理服务及应用商合作，为政务、金融、公安等行业客户提供基础软件平台+应用+服务在内的专业一体化大数据解决方案。
                    </p>
                  </li>
                </ul>
                <strong style={{ marginTop: 66 }}>—&emsp;应用场景&emsp;—</strong>
                <ul className={css.newNav}>
                  <li
                    onMouseOver={() => {
                      setItem4('数据仓库构建');
                    }}
                    className={item4 === '数据仓库构建' ? css.selectd : ''}
                  >
                    <Icon type={Scene0} width="20" height="20" />数据仓库构建
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem4('实时流式数据处理');
                    }}
                    className={item4 === '实时流式数据处理' ? css.selectd : ''}
                  >
                    <Icon type={Scene1} width="21" height="19" />实时流式数据处理
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem4('数据探索挖掘');
                    }}
                    className={item4 === '数据探索挖掘' ? css.selectd : ''}
                  >
                    <Icon type={Scene2} width="18" height="17" />数据探索挖掘
                  </li>
                </ul>
                <div className={css.containScene}>
                  <p>{bigdataProcessingSuite[item4].desc}</p>
                  <img alt={bigdataProcessingSuite[item4].desc} src={bigdataProcessingSuite[item4].img} />
                </div>
              </div>
            </>
          )}
          {current === 5 && (
            <>
              <div className={css.right3}>
                <strong>—&emsp;产品优势&emsp;—</strong>
                <ul className={css.containUl}>
                  <li style={{ height: 120 }}>
                    <span><b />集成解决方案</span>
                    <p>
                    腾讯专有云集成丰富的 IaaS/PaaS/SaaS 产品，机房、网络、服务器、系统、应用统一部署，提供一站式解决方案。
                    </p>
                  </li>
                  <li style={{ height: 120 }}>
                    <span><b />异地多活分布式架构</span>
                    <p>
                    多地多数据中心多活分布式架构，支持数据中心快速扩展，多数据中心多方式网络互通，应对业务高峰挑战。
                    </p>
                  </li>
                  <li style={{ height: 120 }}>
                    <span><b />灵活定制、极速交付</span>
                    <p>
                    弹性交付，根据用户选择业务，灵活定制，实现自动化集成、自动化部署与动态扩容。
                    </p>
                  </li>
                  <li style={{ height: 120 }}>
                    <span><b />源于腾讯成熟产品输出</span>
                    <p>
                    依托腾讯强大技术能力，实现成熟互联网技术云端产品化，历经腾讯10亿海量用户验证及公有云百万客户锤炼。
                    </p>
                  </li>
                </ul>
                <strong style={{ marginTop: 180 }}>—&emsp;产品全景&emsp;—</strong>
                <ul className={css.newNav}>
                  <li
                    onMouseOver={() => {
                      setItem5('行业版');
                      setBlankTop(593, 1450);
                    }}
                    className={item5 === '行业版' ? css.selectd : ''}
                  >
                    行业版
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem5('企业版');
                      setBlankTop(593, 1450);
                    }}
                    className={item5 === '企业版' ? css.selectd : ''}
                  >
                    企业版
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem5('大数据版');
                      setBlankTop(593, 1030);
                    }}
                    className={item5 === '大数据版' ? css.selectd : ''}
                  >
                    大数据版
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem5('AI版');
                      setBlankTop(593, 950);
                    }}
                    className={item5 === 'AI版' ? css.selectd : ''}
                  >
                    AI版
                  </li>
                  <li
                    onMouseOver={() => {
                      setItem5('敏捷版');
                      setBlankTop(593, 1090);
                    }}
                    className={item5 === '敏捷版' ? css.selectd : ''}
                  >
                    敏捷版
                  </li>
                </ul>
                <div className={css.containScene}>
                  <p>{tencentCloudTCE[item5].desc}</p>
                  <img alt={tencentCloudTCE[item5].desc} src={tencentCloudTCE[item5].img} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
    </>
  );
}

export default Products;
