import css from './index.module.scss';

function About() {
  return (
    <div className={css.about}>
      <div className={`${css.content}`}>
        <img className={css.image01} src="/images/index_pic01.png" alt="" />
        <img className={css.image02} src="/images/index_bg04.png" alt="" />
        <div className={css.right}>
          <img src="/images/about01.png" alt="" />
        </div>
        <div className={css.left}>
          <strong>公司文化及理念</strong>
          <p>为腾讯云的用户提供最优的技术服务是我们的长期使命，致力于成为一流的产业互联网服务商、一流的产业互联网解决方案提供商是我们的愿景。我们有着自由度极高的团队，自律性极强的员工，“诚信负责，成就客户”一直是我们的企业价值理念。</p>
        </div>
      </div>
      <div className={css.content}>
        <img className={css.image03} src="./images/soloution_bg.svg" alt="" />
        <div className={css.left} style={{ marginLeft: 0 }}>
          <strong>企业优势</strong>
          <p>腾讯战略投资，作为腾讯云西部地区业务和技术支撑中心，一支所向披靡的技术团队，一个精英备至的领导班子，一群有梦想的中青年，正在云端乘风波浪，创造属于中国西部的腾云梦想。</p>          
        </div>
        <div className={css.right}>
          <img src="/images/about02.png" alt="" />
        </div>
      </div>
      <div className={`${css.content}`} style={{ marginTop: -19 }}>
        <div className={`${css.right} ${css.verification}`}>
          <div className={css.logo}>
            <img src="/images/certification01.png" alt="" />
            贵州省信息技术服务业协会<br />软件企业认证
          </div>
          <div className={css.logo}>
            <img src="/images/certification02.png" alt="" />
            ISO27001认证<br /><br />
          </div>
        </div>
        <div className={css.left}>
          <strong>企业认证</strong>
          <p>公司一路走来，获得多项专项认证及荣誉资质，并通过多家权威机构认证。</p>
        </div>
      </div>
    </div>
  );
}

export default About;
