import { Fragment } from 'react';
import css from './index.module.scss';

function Employment() {
  return (
    <Fragment>
      <div className={css.welfare}>             
        <div className={css.introduce}>
          <img className={css.image02} src="/images/index_bg04.png" alt="" />
          <img src="/images/welfare.png" alt="" />
          <br /><br /><br /><br /><br />我们是一群喜欢挑战的人，在瞬息万变的移动互联网，不断挑战自我，拥抱变化；我们是一群喜欢创新的人，在前进路上不断创新、追求卓越，为了梦想，从未停歇；我们是一支怀揣着梦想的团队，正在云端乘风波浪，创造属于中国西部的腾云梦想。
        </div>
        <dl className={css.containSalary}>
          <img className={css.image01} src="/images/index_pic01.png" alt="" />
          <dt>福利待遇</dt>
          <ul className={css.salary}>
            <li>
              <span><img src="/images/salary01.png" alt="员工激励" />员工激励</span>
              <p>
                年度评优<br />
                即时激励<br />
                伯乐奖<br />
                导师奖
              </p>
            </li>
            <li>
              <span><img src="/images/salary02.png" alt="贴心福利" />贴心福利</span>
              <p>
                五险一金&emsp;&emsp;雇主险<br />
                加班打车&emsp;&emsp;弹性工作<br />
                过节红包&emsp;&emsp;季度团建
              </p>
            </li>
            <li>
              <span><img src="/images/salary03.png" alt="员工关怀" />员工关怀</span>
              <p>
                结婚礼金&emsp;&emsp;生育礼金<br />
                三八节女性半天假<br />
                六一节已育妈妈半天假<br />
              </p>
            </li>
            <li>
              <span><img src="/images/salary04.png" alt="温馨假期" />温馨假期</span>
              <p>
                带薪年假<br />
                带薪病假<br />
                法定假期<br />
                周末双休
              </p>
            </li>
          </ul>
        </dl>
        <div className={css.introduce}>
          <img src="/images/employment02.png" alt="" />
          <br />
          <br /><br />
          <b>发展空间</b>
          <br />
          <br />
          公司一直主张“以人为本”，我们给每一位伙伴，提供了广阔的发展空间；从公司内部培养人才、提拔管理，让员工有丰富的晋升空间，只要你诚信、负责、务实、努力，除升职加薪外，我们给你提供的空间将无限可能！我们公司为你提供了完善的成长机制以及导师机制，你可以选择专业领域资深专家或技术大咖成为你的发展导师，在导师的带领下，不断提升自我，收获成长的喜悦。
        </div>
        <dl>
          <dt>群英会</dt>
          <dd>
            我们非常珍惜和需要你的加入，公司的部分岗位，我们以开放的心态长期招募；公司在蒸蒸日上的发展，我们希望更多优秀的人加入进来，成为云腾团队的一份子。
            <br /><br />1.招聘方向：售前，交付，运维，项目管理等
            <br />2.长期招聘岗位：私有云运维（方向有中间件，网络，数据库，云平台，存储，计算）；私有云交付（方向有数据库，网络，计费，存储，安全等）。
            <br /><br />如果你对我们感兴趣，就赶快联系我们吧！加入我们，开启你人生崭新的旅程！
          </dd>
        </dl>
        <div className={css.position}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.zhipin.com/gongsi/a882ad3904997eaa03F429W-EQ~~.html?ka=search_rcmd_company_name_a882ad3904997eaa03F429W-EQ%7E%7E_custompage"
          >
            查看招聘岗位
          </a>
        </div>
      </div>
      <div className={css.showBackground}>
        <img src="./images/soloution_bg.svg" alt="" />   
      </div>
      <div className={css.heroMetting}>
        <img src="./images/employ.png" alt="" />
        <img src="./images/employ01.svg" alt="" />
      </div>
    </Fragment>
  );
}

export default Employment;
